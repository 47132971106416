import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import { Container, Section } from "../components/Containers"
import Text from "../components/Text"
import Image from "../components/Image"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMobile } from "@fortawesome/pro-light-svg-icons/faMobile"
import { faBuilding } from "@fortawesome/pro-light-svg-icons/faBuilding"
import { telephoneStrip } from "../utils/utils"

const AAOMSThankYou = ({ pageContext, data }) => {
  const { metaTitle, metaDescription, socialImage, schemaPage } = pageContext
  const { heading, blurb, imageId } = data.allUniquePagesJson.nodes[0]
  const location = data.location.nodes[0]

  return (
    <Layout language="en">
      <SEO
        title={metaTitle}
        description={metaDescription}
        socialImage={socialImage}
        schemaPage={schemaPage}
      />
      <Section>
        <Container>
          <div className="homepage__hero">
            <div className="img-txt homepage__hero-img-txt">
              <div className="img-txt__text">
                <Text as="h1" text={heading} />
                <Text as="p" text={blurb} />
              </div>
              <div className="img-txt__image">
                <Image publicId={imageId} />
              </div>
            </div>
          </div>
        </Container>
      </Section>
      <Section>
        <Container>
          <Text
            className="has-text-centered"
            as="h3"
            text={"Centered in San Francisco"}
          />
        </Container>
        <Container maxWidth={800}>
          <div className="contact-page__office">
            <div className="contact-page__office-address">
              <div>
                <FontAwesomeIcon
                  className="contact-page__office-icon"
                  icon={faBuilding}
                />{" "}
                <p>Address</p>
              </div>
              <div>
                <p>
                  {`${location.streetNumber} ${location.streetName}`} <br />{" "}
                  {`${location.cityName}, ${location.stateAbbr} ${location.cityZip}`}{" "}
                </p>
              </div>
            </div>
            <div className="contact-page__office-phone">
              <div>
                <FontAwesomeIcon
                  className="contact-page__office-icon"
                  icon={faMobile}
                />{" "}
                <p>Phone</p>
              </div>
              <div>
                <a
                  href={`tel:${telephoneStrip(location.phoneNumber, 1)}`}
                  title="Give us a call"
                >
                  {location.phoneNumber}
                </a>
              </div>
            </div>
          </div>
        </Container>
      </Section>
      <Section>
        <Container>
          <div className="line-thankyou" />
        </Container>
      </Section>
    </Layout>
  )
}

export const thankyouQuery = graphql`
  query thankyouquery($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        heading
        blurb
        imageId
      }
    }
    location: allLocationsJson {
      nodes {
        cityName
        cityZip
        stateAbbr
        streetName
        streetNumber
        phoneNumber
      }
    }
  }
`

export default AAOMSThankYou
